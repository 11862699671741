import Head from 'next/head';

interface Props {
  title?: string;
  description?: string;
  url?: string;
  imageUrl?: string;
  imageAlt?: string;
  locale?: string;
  siteName?: string;
  type?: string;
  cardType?: string;
  handle?: string;
  noIndex?: boolean;
}

export default function MetaTags({
  title,
  description,
  url,
  imageUrl,
  imageAlt,
  locale,
  siteName,
  type,
  cardType,
  handle,
  noIndex
}: Props) {
  return (
    <Head>
      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </>
      )}

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {url && (
        <>
          <meta property="og:url" content={url} />
          <link rel="canonical" href={url} />
        </>
      )}

      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} />
        </>
      )}

      {imageAlt && (
        <>
          <meta property="og:image:alt" content={imageAlt} />
        </>
      )}

      {locale && (
        <>
          <meta property="og:locale" content={locale} />
        </>
      )}

      {siteName && (
        <>
          <meta property="og:site_name" content={siteName} />
        </>
      )}

      {type && (
        <>
          <meta property="og:type" content={type} />
        </>
      )}

      {cardType && (
        <>
          <meta name="twitter:card" content={cardType} />
        </>
      )}

      {handle && (
        <>
          <meta name="twitter:site" content={handle} />
          <meta name="twitter:creator" content={handle} />
        </>
      )}

      {noIndex && (
        <>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="googlebot" content="noindex,nofollow" />
        </>
      )}
    </Head>
  );
}
