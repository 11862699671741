import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  RetryLink,
  onError
} from '@do/seashell/graphql';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { APOLLO_CLIENT_URI } = publicRuntimeConfig;

const GRAPHQL_PATH = '/graphql/public';

const isBrowser = typeof window !== 'undefined';
let origin = '';

if (isBrowser) {
  origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}

if (!origin) {
  origin = APOLLO_CLIENT_URI;
}

let apolloClientInstance = null;

function create(headers) {
  const httpLink = new HttpLink({
    uri: `${APOLLO_CLIENT_URI}${GRAPHQL_PATH}`,
    credentials: 'include',
    fetch: isBrowser ? window.fetch : fetch,
    headers: { ...headers }
  });

  // Automatically retry up to 3 times, similar to default configuration:
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry
  const retryLink = new RetryLink({
    delay: {
      initial: 750,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error
    }
  });

  // Copied from apollo-link-error readme:
  // https://github.com/apollographql/apollo-link/blob/862b572bf081e5b1d93d8f318290b6fdaa52fb26/packages/apollo-link-error/README.md
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError)
      console.log(`[Network error]: ${JSON.stringify(networkError, null, 2)}`);
  });

  // Strip __typename from variables
  // https://github.com/apollographql/apollo-client/issues/1913#issuecomment-425281027
  const stripTypeNameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      for (const key in operation.variables) {
        // do not touch File objects as JSON.stringify will delete them from the request
        // more info: https://github.com/jaydenseric/apollo-upload-client/issues/142
        if (
          isBrowser &&
          operation.variables[key] &&
          !(operation.variables[key] instanceof File)
        ) {
          operation.variables[key] = JSON.parse(
            JSON.stringify(operation.variables[key]),
            (key, value) => (key === '__typename' ? undefined : value)
          );
        }
      }
    }
    return forward(operation);
  });

  return new ApolloClient({
    name: 'frontend-next',
    version: '0.0',
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([stripTypeNameLink, errorLink, retryLink, httpLink]),
    cache: new InMemoryCache(),
    resolvers: {},
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  });
}

export default function initApollo(headers) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser) {
    return create(headers);
  }

  // Reuse client on the client-side
  if (!apolloClientInstance) {
    apolloClientInstance = create(headers);
  }

  return apolloClientInstance;
}
