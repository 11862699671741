function registerReferral() {
  const matches = window.location.search.match(/refcode=(\w+)/);
  if (matches) {
    const refcode = matches[1];

    fetch(`/referrals/${refcode}/register`, {
      method: 'POST'
    });
  }
}

export { registerReferral };
