import { Experimentation, LOG_LEVEL } from '@do/experimentation';
import React, { createContext, useEffect, useState } from 'react';
import getUserId from '../utils/getUserId';

const defaultState = {
  track: null,
  activate: null,
  experiments: null
};

export const ExperimentationContext = createContext(defaultState);

// use LOG_LEVEL.INFO for development/testing
export function ExperimentationProvider({
  children,
  logLevel = LOG_LEVEL.ERROR,
  userCookie
}) {
  const [experiments, setExperiments] = useState(defaultState);

  useEffect(() => {
    const loadExperiments = async () => {
      try {
        const userId = await getUserId(userCookie);
        if (userId) {
          const exp = new Experimentation(userId, logLevel);
          await exp.loadEnv(() => {
            setExperiments(exp);
          });
        }
      } catch (_err) {
        setExperiments(defaultState);
      }
    };

    loadExperiments();
  }, []);

  return (
    <ExperimentationContext.Provider value={experiments}>
      {children}
    </ExperimentationContext.Provider>
  );
}
