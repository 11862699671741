import { BrowserOptions } from '@sentry/browser';
import * as SentryNode from '@sentry/node';
import { NodeOptions } from '@sentry/node';
import { Integrations } from '@sentry/tracing';

/**
 * The @sentry/node import is rewritten to use @sentry/browser using webpack inside
 * of the next.config.js file in order to avoid importing incompatible Node.js APIs
 * inside the browser, and vice versa. Please refer to that file for more details.
 */
const IsomorphicSentry = () => {
  const SENTRY_DSN =
    'https://d8e86bf7ac4a474a9e116ed845e273af@o45439.ingest.sentry.io/1309986';

  if (process.browser === true) {
    const browserOptions: BrowserOptions = {
      environment: process.env.NODE_ENV,
      dsn: SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
      allowUrls: [
        /https?:\/\/(([a-zA-Z0-9][a-zA-Z0-9.-]+)\.)?digitalocean\.com/
      ],
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.01,
      ignoreErrors: [
        '/^sentry-ignore/',
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'window.analytics.user is not a function',
        /^Non-Error promise rejection captured with value:\s?$/,
        /^Non-Error promise rejection captured with value:/,
        'Error: AbortError: The operation was aborted.',
        'Error: AbortError: The user aborted a request.',
        "ReferenceError: Can't find variable: twttr",
        "ReferenceError: Can't find variable: instantSearchSDKJSBridgeClearHighlight'",
        "ReferenceError: Can't find variable: WeixinJSBridge'",
        'ReferenceError: playBackgroundMusic is not defined',
        'ReferenceError: $ is not defined',
        'ReferenceError: jQuery is not defined',
        'ReferenceError: privateSpecialRepair is not defined',
        "TypeError: Cannot read property 'getReadModeExtract' of undefined",
        "TypeError: Cannot read property 'getReadModeConfig' of undefined",
        "TypeError: Cannot read property 'getReadModeRender' of undefined",
        "TypeError: null is not an object (evaluating 'document.head.querySelector(\"meta[name='supported-color-scheme...",
        'TypeError: window.bannerNight is not a function',
        "TypeError: Cannot read properties of null (reading 'disconnect')",
        'TypeError: Cannot redefine property: BetterJsPop',
        "TypeError: undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
        /^ReferenceError: Can't find variable: al_.*/
      ]
    };

    SentryNode.init(browserOptions);
  } else {
    const nodeOptions: NodeOptions = {
      environment: process.env.NODE_ENV,
      dsn: SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_SENTRY_RELEASE
    };

    SentryNode.init(nodeOptions);
  }

  return SentryNode;
};

export default IsomorphicSentry;
