function getCookieByName(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
}

export default function getUserId(cookieName = 'ajs_anonymous_id') {
  const anonId = getCookieByName(cookieName);

  if (anonId !== '' && anonId !== undefined) {
    return Promise.resolve(anonId);
  }

  // If we don't have the cookie, lets also make sure the analytics.js
  // isn't missing and has been loaded. This shouldn't
  // happen, but just in case, we've added a little extra protection.
  return new Promise<void | string>((resolve, _reject) => {
    if (typeof (window as any).analytics === 'undefined') {
      resolve();
      return;
    }

    (window as any).analytics?.ready(() => {
      const user =
        typeof (window as any).analytics?.user === 'function' &&
        (window as any).analytics?.user();

      resolve(user?.anonymousId());
    });
  });
}
