import { ApolloProvider } from '@do/seashell/graphql';
import { WalrusGlobalStyle } from '@do/walrus';

import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import withApolloClient from '../utils/with-apollo-client';
import { registerReferral } from '../utils/referrals';
import { ExperimentationProvider } from '../components/ExperimentationContext';
import MetaTags from '../components/MetaTags';

class MyApp extends App {
  async componentDidMount() {
    registerReferral();
  }

  render() {
    const { Component, pageProps, apolloClient } = this.props;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>

        <MetaTags
          locale="en_US"
          siteName="DigitalOcean"
          type="website"
          cardType="summary"
          handle="@DigitalOcean"
        />

        <WalrusGlobalStyle />

        <ApolloProvider client={apolloClient}>
          <ExperimentationProvider>
            <Component {...pageProps} />
          </ExperimentationProvider>
        </ApolloProvider>

        <Script
          src="https://assets.digitalocean.com/prism/prism.js"
          strategy="lazyOnload"
        />
      </>
    );
  }
}

export default withApolloClient(MyApp);
