// from: https://github.com/zeit/next.js/tree/master/examples/with-apollo
import { getDataFromTree } from '@apollo/react-ssr';
import _get from 'lodash/get';
import React from 'react';
import initApollo from './init-apollo';
import IsomorphicSentry from './sentry';

const sentryInstance = IsomorphicSentry();

const withApolloClient = (App) => {
  return class Apollo extends React.Component {
    public static displayName = 'withApollo(App)';
    public static async getInitialProps(ctx) {
      const { AppTree } = ctx;
      const { headers, cookies } = ctx.ctx.req || {};

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx, headers);

        const data = {
          headers,
          ...appProps
        };

        appProps = { ...data };
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApollo({});

      if (typeof window === 'undefined') {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <AppTree {...appProps} apolloClient={apollo} />
          );
        } catch (error) {
          const statusCode = _get(
            error,
            'graphQLErrors[0].extensions.response.status'
          );

          if (statusCode < 400) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            console.error('Error while running `getDataFromTree`', error);
            sentryInstance.captureException(
              `Error while running 'getDataFromTree', ${error}`
            );
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        headers,
        cookies
      };
    }

    constructor(props) {
      super(props);
      (this as any).apolloClient = initApollo(props.headers);
    }

    public render() {
      // apolloClient must be first for SSR
      return <App apolloClient={(this as any).apolloClient} {...this.props} />;
    }
  };
};

export default withApolloClient;
